<template>
    <v-card color="#F6F9FC" flat class="rounded-0 pt-6">
        <v-container>
            <v-row v-if="details">
                <v-col cols="12" lg="9">

                    <v-card flat class="pa-4">

                        <div class="text-center">
                            <img :src="details.cover_img" alt="" style="max-width: 100%;">
                        </div>

                        <div class="text-center my-4">
                            <a :href="details.link" target="_blank">
                                <v-btn color="primary">{{ $t('实时预览') }}
                                    <v-icon>mdi-application</v-icon>
                                </v-btn>
                            </a>
                        </div>
                        <v-card color="rgba(115, 49, 255, 0.06)" flat class="pa-4 mb-4">
                            <span>{{ $t('发表时间') }}：{{ details.createtime }}</span>
                            <span class="pl-lg-12 pl-2">{{ $t('更新时间') }}：{{ details.updatetime }}</span>
                            <span class="pl-lg-12 pl-2">{{ $t('模版ID') }}：{{ details.themes_id }}</span>
                        </v-card>

                        <p>{{ details.des }}</p>
                        <v-card color="#f7f7f7" class="pa-4" flat>
                            <v-card-title primary-title class="px-0 pt-0">
                                {{ $t('主题模板特点') }}
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <ul class="px-0">
                                    <li v-for="(fitem, fidx) in details.features" :key="fidx" style="list-style: disc;"
                                        class="black--text mb-2">{{ fitem }}</li>
                                </ul>
                            </v-card-text>
                        </v-card>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-card flat class="pa-3">
                        <v-card-title class="px-0 pt-2 pb-0 d-flex justify-space-between">
                            <span class="text-h6 font-weight-bold">
                                {{ $t('模版价格') }}
                            </span>
                            <span class="text-h6 font-weight-bold" v-if="details.type == 2">
                                {{ details.symbol }}{{ details.price }}
                            </span>
                            <v-chip v-else color="success" class="px-4"> {{ $t('免费') }}</v-chip>

                        </v-card-title>
                        <v-divider class="my-3 "></v-divider>
                        <div v-if="details.is_buy == 0">


                            <div v-if="details.type == 2">
                                <p>{{ $t('支付方式') }}</p>
                                <div>
                                    <div v-for="(pitem, pidx) in paylist" :key="pidx"
                                        class="pa-2 cursor default-pay rounded-lg"
                                        :class="pay_type == pitem.pay_type ? 'checked-pay' : ''" @click="pay_type = pitem.pay_type">
                                        <img :src="pitem.logo" alt="alt" height="48">
                                    </div>

                                </div>
                                <v-btn color="primary" width="100%" class="my-4" @click="tocreate">{{ $t('立即购买') }}</v-btn>
                            </div>
                            <v-btn color="primary" width="100%" class="mb-4" @click="tocreate" v-else>{{ $t('立即免费使用')
                            }}</v-btn>
                        </div>
                        <div v-else>
                            <v-btn color="primary" width="100%" class="mb-4" disabled>{{ $t('已购买') }}</v-btn>
                        </div>
                        <!-- <p class="text-center grey--text text--darken-1 mt-4 mb-0">{{ $t('价格以人民币为单位，不含税') }}</p> -->
                        <v-card-text class="pa-0">
                            <v-list dense rounded>
                                <v-list-item-group color="primary">
                                    <v-list-item class="px-0">
                                        <v-list-item-icon class="mr-2">
                                            <v-icon color="success">mdi-check-circle-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('未来更新') }}</v-list-item-title>

                                        </v-list-item-content>

                                    </v-list-item>
                                    <v-list-item class="px-0">
                                        <v-list-item-icon class="mr-2">
                                            <v-icon color="success">mdi-check-circle-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('提供6个月的支持') }}</v-list-item-title>

                                        </v-list-item-content>

                                    </v-list-item>
                                    <v-list-item class="px-0">
                                        <v-list-item-icon class="mr-2">
                                            <v-icon color="success">mdi-check-circle-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('由taoify检查质量') }}</v-list-item-title>

                                        </v-list-item-content>

                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>

                        </v-card-text>
                    </v-card>
                    <v-card flat class="pa-3 mt-4">
                        <v-card-title class="px-0 pt-2">
                            <span class="text-h6 font-weight-bold">
                                {{ $t('关于作者') }}
                            </span>

                        </v-card-title>
                        <div class="d-flex justify-space-between align-center mb-6 overflow-hidden">
                            <div class="d-flex flex-1 align-center">
                                <v-avatar size="60" v-if="!details.avatar">
                                    <img src="@/assets/images/template/demo.png" alt="alt">
                                </v-avatar>
                                <v-avatar size="60" v-else>
                                    <img :src="details.avatar" alt="alt">
                                </v-avatar>
                                <div class="pl-4">
                                    <p class="text-body-1 font-weight-bold mb-2">{{ details.nickname }}</p>
                                </div>

                            </div>
                            <!-- <v-btn color="rgba(115, 49, 255, 0.10)" class="primary--text  " depressed small>{{ $t('查看详情')
                            }}</v-btn> -->
                        </div>
                        <v-divider class="mt-3 " light></v-divider>
                        <v-card-text class="pa-0" v-if="details.other_themes&&details.other_themes.length>0">
                            <h6 class="black--text text--lighten-1 py-3 text-body-1">{{ $t('其他作品') }}</h6>
                            <v-row>
                                <v-col cols="6" v-for="n in details.other_themes" :key="n.themes_id" @click="go_detail(n.themes_id)">
                                    <img :src="n.cover_img" alt="" width="100%">
                                    <p class="mb-0">{{ n.name }}</p>
                                </v-col>

                            </v-row>

                        </v-card-text>
                    </v-card>
                    <v-card flat class="pa-3 mt-4">
                     
                        <v-row>
                            <v-col cols="4"><span class="grey--text text--darken-4">{{ $t('高分辨率') }}</span></v-col>
                            <v-col cols="8" class="grey--text text--darken-4" v-if="details.is_high_resolution==1">{{ $t('是的') }}</v-col>
                            <v-col cols="8" class="grey--text text--darken-4" v-else>{{ $t('不支持') }}</v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="4"><span class="grey--text text--darken-4">{{ $t('可兼容浏览器') }}</span></v-col>
                            <v-col cols="8" class="grey--text text--darken-4">{{details.support_browsers}}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4"><span class="grey--text text--darken-4">{{ $t('布局') }}</span></v-col>
                            <v-col cols="8" class="grey--text text--darken-4">{{ details.layout }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4"><span class="grey--text text--darken-4">{{ $t('标签') }}</span></v-col>
                            <v-col cols="8" class="grey--text text--darken-4">
                                <span v-for="item in details.themes_tag_info" :key="item.themes_tag_id">{{ item.name }}/</span>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="usedShow">
            <v-card width="700" flat >
              
                <v-card-text class="pa-6">
                        <div>{{ $t('购买成功！请在模板中心查看使用。'), }} 
                        </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  text @click="usedShow = false">{{ $t('仅关闭') }}</v-btn>
                    <a href="https://taoify.shop/user-index" target="_blank" small><v-btn  text @click="usedShow = false">{{ $t('查看已购买模版') }}</v-btn></a>
                    <v-btn text color="primary darken-1" @click="touse">{{ $t('应用到当前店铺')}}</v-btn>
                   
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
export default {
    data() {
        return {
            details: null,
            themes_id: 0,
            pay_type: 1,//1=支付宝，2=paypal
            paylist: [],
            usedShow:false,//立即使用弹窗
        }
    },
    watch:{
        "$route.query.themes_id"(val){
  
            this.getdetail();
        }
    },
    mounted() {
   
        this.getdetail();

    },
    methods: {
        getdetail() {
            this.themes_id = this.$route.query.themes_id;
            this.$api.template.detail({ themes_id: this.themes_id }).then(res => {
                if (res.status) {
                    this.details = res.data;
                    if (res.data.type == 2) {
                        this.getpaytype();
                    }
                }
            })
        },
        getpaytype() {
            this.$api.template.paytype().then(res => {
                if (res.status) {
                    this.paylist = res.data;

                }
            })
        },
        tocreate() {
            this.$api.template.create({ themes_id: this.themes_id }).then(res => {
                if (res.status) {
                    if (this.details.type == 1) {
                        this.$Toast({ content: this.$t('购买成功') });
                        this.usedShow=true;
                        this.getdetail();
                    } else {
                        this.topay(res.data);
                    }

                }
            })
        },
        touse(){
            this.$api.template.use_themes({ themes_id: this.themes_id }).then(res => {
                this.usedShow=false;
                if (res.status) {
                    this.$Toast({ content: res.msg });
                } else {
                    this.$Toast({ content: res.msg, type: 'error' });
                }
            })
        },
        topay(sn) {
            this.$api.template.payed({ order_sn: sn, pay_type: this.pay_type }).then(res => {
                if (res.status) {
                    window.open(res.data)
                }
            })
        },
        go_detail(id){
            this.$router.push({ path: '/template-detail', query: { themes_id: id } }) 
        }
    }
}
</script>
<style>
.filter-price-input {
    background-color: #F7F7F7;
}

.default-pay {
    border: solid 2px transparent;
}

.checked-pay {
    border: solid 2px var(--v-primary-base);
}</style>